<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Incentive Top-up"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="700px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('import_topup_lists','incentive_TPC','incentive_1112D')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                       <v-select
                        :items="allIncentives"
                        label="Incentive Programs"
                        v-model="editedItem.incentive_program_id"
                        :disabled="editedItem.id !== -1"
                        :rules="[validate_rules.required]"
                        no-data-text="Select.."
                        item-text="name"
                        item-value="id"
                        return-object
                        @input="setSelected($event)"
                        clearable
                      ></v-select>
                    </v-col>
                     <v-col cols="12" sm="12" md="12">
                      <v-menu
                        v-model="datePicker"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Incentive Date"
                            :value="incentive_date_formatted"
                            :disabled="editedItem.id !== -1"
                            :rules="[validate_rules.required]"
                            readonly
                            v-on="on"
                            @click:clear="date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.incentive_date"
                          @change="datePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                          v-model="editedItem.topup_file"
                          :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                          accept="text/csv"
                          placeholder="Browse a csv file"
                          label="Topup List"
                      ></v-file-input>
                  </v-col>

                  <v-col cols="12" sm="12" md="12" v-show="canDo && canDo.can.some(r => ['incentive_TPC','incentive_1112D'].indexOf(r) >= 0)">
                        <v-radio-group v-model="editedItem.wallet_code">
                            <template v-slot:label>
                                <div>Incentive wallet</div>
                            </template>
                            <v-radio v-show="canDo && canDo.can.includes('incentive_TPC')"
                                label="incentive-TPC"
                                value="TPC"
                            ></v-radio>
                            <v-radio v-show="canDo && canDo.can.includes('incentive_1112D')"
                                label="incentive-1112D"
                                value="1112D"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>

                  <v-col cols="12" sm="12" md="12" v-for="(item) in editedItem.sms_template" :key="item.id" class="pb-0 pt-0">
                      <v-row>
                        <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                          <v-text-field v-model="item.prefix" :rules="validate_rules.name" label="Prefix"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-0" v-else>
                            <v-text-field v-model="item.prefix" :rules="validate_rules.name" label="Prefix"></v-text-field>
                          </v-col>
                        <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                            <div>
                              <p style="font-size:10px;" class="mb-1">param name</p>
                              <span >{{item.param_name}}</span>
                              <hr>
                            </div>
                        </v-col>
                        <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                          <v-text-field v-model="item.suffix" :rules="validate_rules.name" label="Suffix"></v-text-field>
                        </v-col>
                      </v-row>
                  </v-col>
                  
                  </v-row>
                  <hr v-if="editedItem.sms_template">
                  <br v-if="editedItem.sms_template">
                  <p v-if="editedItem.sms_template">ตัวอย่างข้อความ sms : </p>
                  <span v-for="(item) in editedItem.sms_template" :key="item.id" style="font-style:oblique;">
                    {{item.prefix}} {{paramName(item.param_name)}} {{item.suffix}}
                  </span>

                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 

        <v-dialog v-model="dialogEditSMS" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit SMS template</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" v-for="(item) in editedItem.sms_template" :key="item.id" class="pb-0 pt-0">
                        <v-row>
                          <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                            <v-text-field v-model="item.prefix" :rules="validate_rules.name" label="Prefix"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12" class="pb-0" v-else>
                            <v-text-field v-model="item.prefix" :rules="validate_rules.name" label="Prefix"></v-text-field>
                          </v-col>
                          <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                            <div>
                              <p style="font-size:10px;" class="mb-1">param name</p>
                              <span >{{item.param_name}}</span>
                              <hr>
                            </div>
                          </v-col>
                          <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                            <v-text-field v-model="item.suffix" :rules="validate_rules.name" label="Suffix"></v-text-field>
                          </v-col>
                        </v-row>
                    </v-col>
                  </v-row>
                  <hr v-if="editedItem.sms_template">
                  <br v-if="editedItem.sms_template">
                  <p v-if="editedItem.sms_template">ตัวอย่างข้อความ sms : </p>
                  <span v-for="(item) in editedItem.sms_template" :key="item.id" style="font-style:oblique;">
                    {{item.prefix}} {{paramName(item.param_name)}} {{item.suffix}}
                  </span>

                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="editedSMS">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-4" flat>
          <!-- Action Button -->
          <!-- <v-spacer></v-spacer> -->
          <v-col class="d-flex px-0" cols="3" sm="3">
            <v-select
              :items="allIncentives"
              label="Programs"
              v-model="options.filter.program"
              no-data-text="Select.."
              item-text="name"
              item-value="id"
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="3" sm="3">
            <v-menu
              v-model="filterDatePicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="filter_date_formatted"
                  label="Incentive Date"
                  readonly
                  v-on="on"
                  @click:clear="options.filter.date = null"
                  hide-details
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.filter.date"
                @change="filterDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="IncentiveTopupsStatus"
              label="Status"
              v-model="options.filter.status"
              dense
              clearable
              hide-details
            ></v-select>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-btn class="mx-2" depressed dark small color="primary" @click="getIncentiveTopups(options);">
            Search
          </v-btn>
        </v-toolbar>
        <v-data-table 
            :headers="headers"
            :items="dataLisings" 
            :options.sync="options"
            :server-items-length="totalResult"
            :loading="loadingDataTable"
            multi-sort
            no-data-text="There is no data"
        >
          
          <template #item.incentive_date="{value}">
              {{ value | date}}
          </template>
          <template #item.topup_amount="{value}">
              {{ value | comma}}
          </template>
          <template #item.updatedAt="{value}">
              {{ value | datetime}}
          </template>
          <template #item.action="{item}">
                <v-btn class="ma-1" icon tile small outlined color="primary" :disabled="!item.incentive_program_is_deduct">
                  <v-icon small @click="editItemModal(item)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn 
                  v-if="canDo && canDo.can.includes('import_topup_lists')"
                  class="ma-1" outlined color="primary" icon tile small :disabled="['approve_request','approved'].includes(item.status)">
                  <v-icon small @click="editItem(item)">mdi-upload</v-icon>
                </v-btn>
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <v-icon small @click="()=>$router.push(`/incentive/topup/${item.id}/details`)">mdi-text-box</v-icon>
                </v-btn>
                <v-btn 
                  v-if="canDo && canDo.can.includes('delete')"
                  class="ma-1" outlined color="red" icon tile small :disabled="['approve_request','approved'].includes(item.status)">
                  <v-icon small @click="deleteItem(item)">mdi-trash-can-outline</v-icon>
                </v-btn>
          </template>
        </v-data-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'IncentiveTopups',
  components: {
      pageHeading
  },
  data: () => ({
    search: '',
    dialog: false,
    dialogEditSMS : false,
    datePicker: false,
    filterDatePicker: false,
    upload_file: null,
    incentive_program_name : '',
    editedItem: {
      id: -1,
      incentive_program_id: '',
      is_deductable : false,
      incentive_date: '',
      topup_file: null,
      incentive_program_is_deduct : false,
      sms_template : null,
      replace: true,
      wallet_code : null
    },
    defaultItem: {
      id: -1,
      incentive_program_id: '',
      incentive_date: '',
      topup_file: null,
      incentive_program_is_deduct : false,
      sms_template_default : null,
      sms_template_deduct : JSON.parse(`"${process.env.VUE_APP_SMSTEMPLATE_DEDUCT}"`),
      replace: false,
      wallet_code : null
    },
    form_valid : false,
    validate_rules : {
        required: v => !!v || 'This field is required',
        select2: v => {
          if(typeof v === 'object'){
            return (v.value !== '') || 'This field is required';
          } else {
            return true
          }
        },
        // select: v => { return (v !== '') || 'This field is required' },
        filesize:  v => !v || v.size < 2000000 || 'Updload size should be less than 2 MB!',
        // filetype:  v => !v || ["text/csv"].includes(v.type) || 'Please upload .csv file only',
        filetype:  v => {
          if(v){
            var ext = v.name.split('.').pop();
            return ["csv"].includes(ext) || 'Please upload .csv file only';
          } else {
            return 'This field is required'
          }
        }
        // excel type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    options: { 
        sortBy: ['updatedAt'],
        sortDesc: [true],
        multiSort: true,
        mustSort: true,
        filter: {
            program: '',
            date: '',
            status: '',
        },
    },
  }),
  watch: {
    'options.sortBy': function (newVal, oldVal){
         //to work with changes in someOtherProp
        if(newVal){
          this.getIncentiveTopups(this.options);
        }
     },
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    ...mapActions(['genCan','getIncentiveTopups','getIncentives','createIncentiveTopups','deleteIncentiveTopups','updateIncentiveSMS']),
    addItem(){
      this.dialog = true;
    },
    editItem (item) {
      this.editedIndex = this.allIncentiveTopups.data.indexOf(item)
      this.editedItem =  _.cloneDeep(item)
      this.editedItem.replace = true
      this.dialog = true
    },
    deleteItem (item) {
        confirm('Are you sure you want to delete this topup?') && this.deleteIncentiveTopups(item.id);
    },
    close () {
      this.dialog = false
      this.dialogEditSMS = false
      setTimeout(() => {
        this.editedItem = _.cloneDeep(this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },
    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.editedItem.replace = true;
        } else {
          this.editedItem.replace = false;
        }
        this.createIncentiveTopups(this.editedItem);
        this.close()
      }
    },
    editItemModal(item) {
    
      this.incentive_program_name = item.incentive_program_name
      if(!item.sms_template){
        if(item.incentive_program_is_deduct){
          item.sms_template = this.defaultItem.sms_template_deduct
        }
      }else{
        if(item.incentive_program_is_deduct){
          if(item.sms_template.length !== this.defaultItem.sms_template_deduct.length){
            item.sms_template = this.defaultItem.sms_template_deduct
          }
        }
      }

      this.editedItem = _.cloneDeep(item)
      this.dialogEditSMS = true
    },
    setSelected(item){
        this.editedItem.incentive_program_id = item.id
        this.incentive_program_name = item.name
        if(item.is_deductable){
          this.editedItem.sms_template = this.defaultItem.sms_template_deduct
        }else{
          this.editedItem.sms_template = this.defaultItem.sms_template_default
        }
    },
    editedSMS () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.editedItem.replace = true;
        } else {
          this.editedItem.replace = false;
        }
        this.updateIncentiveSMS(this.editedItem);
        this.close()
      }
    },
    paramName(param_name) {
      if(param_name == 'incentive_program'){
        return this.incentive_program_name
      }else if(param_name == 'incentive_date'){
        return this.editedItem.incentive_date
      }else if(param_name == 'amount'){
        return 999
      }else if(param_name == 'equipment_name'){
        return 'Heat Plate'
      }else if(param_name == 'deduct_amount'){
        return '90'
      }else if(param_name == 'remain_amount'){
        return '900'
      }else{
        return param_name
      }
    }
  },
  computed: {
    ...mapGetters(['canDo','loadingDataTable','allIncentiveTopups','allIncentives','IncentiveTopupsStatus']),
    dataLisings() { 
        var res = this.allIncentiveTopups ? this.allIncentiveTopups.data : [];
        return  res;
    },
    totalResult(){
        return (this.allIncentiveTopups ? this.allIncentiveTopups.count : null) || 0;
    },
    headers(){
      return [
        {text: "Programs", value: 'incentive_program_name',width: '20%'},
        {text: "Date", value: 'incentive_date', align: 'center',width: '10%'},
        {text: "Driver Amount", value: 'driver_amount', align: 'right',width: '12%'},
        {text: "Total Top-up", value: 'topup_amount',align: 'right',width: '12%'},
        {text: "Paid Wallet", value: 'wallet_code',align: 'center',width: '12%'},
        {text: "Status", value: 'status',align: 'center',width: '15%'},
        {text: "Last Update", value: 'updatedAt',align: 'center',width: '15%'},
        {text: "Action", value: 'action', sortable: false, align: 'center', width: '200px'}, 
      ]
    },

    formTitle () {
        return this.editedIndex === -1 ? 'Upload Incentive Topups' : 'New Incentive Topups'
    },
    incentive_date_formatted () {
        return this.editedItem.incentive_date ? moment(this.editedItem.incentive_date).format('DD-MM-YYYY') : '';
    },
    filter_date_formatted () {
        return this.options.filter.date ? moment(this.options.filter.date).format('DD-MM-YYYY') : '';
    },
  },
  mounted() {
    this.genCan();
    this.getIncentives();
    this.getIncentiveTopups(this.options);
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>